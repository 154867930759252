.la-notification {
  position: fixed;
  color: white;
  font-size: 12px;
  left: 8px;
  top: 8px;
  right: 8px;
  z-index: 1009;
}
.la-notification .content {
  padding: 8px 30px 8px 20px;
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
  color: inherit;
  text-decoration: none;
  visibility: hidden;
  transition: all 0.6s;
  border-radius: 3px;
  text-shadow: 0 0 3px rgba(1, 1, 1, 0.3);
  background-color: transparent;
  line-height: 150%;
  font-size: 14px;
}
.la-notification.active .content.warn {
  background-color: #ff9800;
}
.la-notification.active .content.error {
  background-color: #e51c23;
}
.la-notification.active .content.default {
  background-color: #2196f3;
}
.la-notification.active .content.success {
  background-color: #8bc34a;
}
.la-notification.active .content.dark {
  background-color: #414141;
}
.la-notification.active .content {
  opacity: 1;
  visibility: visible;
}
.la-notification.active .close {
  position: absolute;
  right: 14px;
  top: 12px;
  display: inline-block;
  width: 14px;
  height: 14px;
  overflow: hidden;
  cursor: pointer;
}
.la-notification.active .close::before,
.la-notification.active .close::after {
  content: '';
  position: absolute;
  height: 1px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #fff;
}
.la-notification.active .close::before {
  transform: rotate(45deg);
}
.la-notification.active .close::after {
  transform: rotate(-45deg);
}

.antui-notification-success {
  background: #cddc39;
}
.antui-notification-success .ant-notification-notice-message,
.antui-notification-success .ant-notification-notice-description {
  color: #1b5e20;
}
.antui-notification-success .ant-notification-notice-icon {
  color: #e6ee9c;
}
.antui-notification-error {
  background: #e51c23;
}
.antui-notification-error .ant-notification-notice-message,
.antui-notification-error .ant-notification-notice-description {
  color: #ffebee;
}
.antui-notification-error .ant-notification-notice-icon {
  color: #f28e91;
}
.antui-notification-warning {
  background: #ff9800;
}
.antui-notification-warning .ant-notification-notice-message,
.antui-notification-warning .ant-notification-notice-description {
  color: #fff3e0;
}
.antui-notification-warning .ant-notification-notice-icon {
  color: #ffcc80;
}
.antui-notification-info {
  background: #2196f3;
}
.antui-notification-info .ant-notification-notice-message,
.antui-notification-info .ant-notification-notice-description {
  color: #e3f2fd;
}
.antui-notification-info .ant-notification-notice-icon {
  color: #90cbf9;
}

table.dataTable thead>tr>th input.ant-calendar-range-picker-input {
    width: 44% !important;
}
.abox {
    height: 38px;
    line-height: 38px;
    text-align: center;
    border: 1px solid gray;
}

.time-picker {
    margin: 15px 15px 15px 0px;
}
.table-b {
    margin: 15px 0px 15px 0px;
}

